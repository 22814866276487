<template>
    <div class="landing-image-large landing-image-small"> 
        <navbar />
        <v-row>
            <v-col cols="12" xs="2" sm="0" md="0" lg="1"></v-col>
            <v-col cols="12" xs="2" sm="0" md="0" lg="5">
                <div class="encart-blanc">
                    <p class="text-landing">
                        {{ $t('pageLandingPage.text[0]') }}
                        <br><br> 
                        {{ $t('pageLandingPage.text[1]') }}
                        <br><br> 
                        {{ $t('pageLandingPage.text[2]') }}
                        <br><br> 
                        {{ $t('pageLandingPage.text[3]') }}
                    </p>
                </div>
                <div class="button-container">
                    <button @click="showCalendar = true" class="button-contact">{{ $t('button.contact') }}</button>
                </div>
            </v-col>
            <v-col cols="12" xs="2" sm="0" md="0" lg="1"></v-col>
            <v-col cols="12" xs="8" sm="12" md="12" lg="4">
                <div class="implant-img">
                    <img src="../images/ImplantFleche.png" alt="">
                </div>
                <div class="title-container">
                    <h1>MiceTracking</h1>
                    <h2>{{ $t('pageLandingPage.underTitle') }}</h2>
                    <div>
                        <button @click="showCalendar = true" class="button-contact">{{ $t('button.contact') }}</button>
                    </div>
                </div>
                <div class="implant-container-large">
                    <img src="../images/Implant.png" alt="">
                </div>
            </v-col>
            <v-col cols="12" xs="2" sm="0" md="0" lg="1"></v-col>
        </v-row>
        <v-row >
            <v-col cols="12" sm="0"></v-col>
            <v-col cols="12" sm="12">
                <div class="souris-container">
                    <img src="../images/SourisMain.png" alt="">
                </div>
                <div style="width: 100%; display: flex; justify-content: center;" >
                    <div class="encart-small">
                        <p class="text-landing">
                            {{ $t('pageLandingPage.text[0]') }}
                            <br><br> 
                            {{ $t('pageLandingPage.text[1]') }}
                            <br><br> 
                            {{ $t('pageLandingPage.text[2]') }}
                            <br><br> 
                            {{ $t('pageLandingPage.text[3]') }}
                        </p>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" lg="6"></v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12" md="12" lg="12" style="padding: 0;">
                <div class="copyright1"> 
                    <p>{{ $t('footer.copyright') }}</p>
                </div>
            </v-col>
        </v-row>
        <v-dialog v-model="showCalendar" width="500">
            <div class="calendar-container">
                <iframe src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ227XjU2_7o3fYFtNrdvXxo6GkQss79GtNFncjEhWQdfyySnztLEkO9S0PcT-TQGmE5A2JEXLU1?gv=true" style="border: 0" width="100%" height="600" frameborder="0"></iframe>
            </div>
        </v-dialog>
    </div>
</template>

<script>
import navbar from '@/components/NavBarLandingPage.vue'

export default {
    components: {
        navbar
    },
    metaInfo: {
        title: '',
        meta: [
            {
                name: '',
                content: ''
            },
            {
                name: 'keywords',
                content: ''
            }
        ]
    },
    data() {
        return {
            scrollPosition: 0,
            maxScroll: 125, // Par exemple, la position maximale où l'image doit s'arrêter
            showCalendar: false,
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            this.scrollPosition = window.scrollY;

            const implantContainer = this.$el.querySelector('.implant-container-large');
            let translateY = Math.min(this.scrollPosition / 2, this.maxScroll);
            implantContainer.style.transform = `translateY(${translateY}px)`;

            const encartBlanc = this.$el.querySelector('.encart-blanc');

            if (this.scrollPosition > 0) {
                encartBlanc.style.transform = `translateY(-105px)`;
                encartBlanc.style.opacity = `1`;
                encartBlanc.style.height = 'auto';
            } else {
                encartBlanc.style.transform = `translateY(0px)`;
                encartBlanc.style.opacity = `0`;
            }

            const encartSmall = this.$el.querySelector('.encart-small');
            if (this.scrollPosition > 500) {
                encartSmall.style.transform = `translateY(-50px)`;
                encartSmall.style.opacity = `1`;
                encartSmall.style.height = 'auto';
            } else {
                encartSmall.style.transform = `translateY(0px)`;
                encartSmall.style.opacity = `0`;
            }
        }
    }
}
</script>

<style>
.landing-image-large {
    background-image: url('../images/LandingPage1.jpg');
    background-color: #C8D2DC;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    display: block;
    margin-top: -100px; /* Vérifiez si c'est vraiment nécessaire et ajustez si besoin */
}

.title-container {
    margin-top: 180px;
    width: auto;
}

.title-container h1 {
    color: var(--vertfonce);
    font-family: "Roboto", Bold;
    font-weight: 700;
    font-size: 6vw;
}

.title-container h2 {
    color: var(--bleu);
    font-family: "Roboto", Regular;
    font-weight: 700;
    font-size: 2.2vw;
}

.title-container div {
    width: 100%;
    display: flex;
    justify-content: right;
}

.title-container button {
    margin-top: 2vw;
}

.button-contact {
    background-color: var(--vertfonce);
    padding: 0.2vw 2vw;
    border-radius: 20px;
    color: white;
    border: solid 2px var(--vertfonce);
    font-family: "Roboto", Regular;
    font-size: 1.2vw;
    transition: background 300ms ease-in-out;
}

.button-contact:hover {
    background-position: 100%;
    color: var(--vertfonce);
    background-color: transparent;
}

.button-container {
    width: 90%;
    margin-top: -80px;
    display: flex;
    justify-content: right;
}

.implant-container-small,
.implant-img,
.souris-container,
.encart-small {
    display: none;
}

.implant-container-large {
    display: block;
    margin-top: 50px;
    margin-left: 7vw;
    transition: transform 0.3s ease-out;
}

.implant-container-large img {
    width: 15vw;
}

.calendar-container {
    background-color: white;
    border-radius: 15px;
}

.text-landing {
    font-family: "Roboto", Regular;
    font-size: 1.4rem;
    color: var(--gris);
    text-align: justify;
}

.encart-blanc {
    display: block;
    background-color: white;
    border-radius: 20px;
    opacity: 0;
    margin-top: 37vw;
    width: 90%;
    padding: 40px;
    transition: transform 1s ease-out, opacity 0.7s ease-out;
}

.copyright1 {
    text-align: center;
    font-size: 21px;
    color: #333; /* Ensure this contrasts with the background */
    margin-top: 50px;
    width: 100%;
}

@media (max-width: 1264px) {
    .implant-container-large {
        display: none;
    }

    .landing-image-large {
        background-image: none;
        height: auto;
    }

    .implant-container-small {
        margin-top: 320px;
        width: 100%;
        display: flex;
        justify-content: center;
        transition: transform 0.3s ease-out;
    }

    .button-contact {
        font-size: 16px;
        padding: 10px 20px;
    }

    .implant-container-small img {
        width: 40%;
    }

    .implant-img {
        display: flex;
        justify-content: center;
        margin-top: 300px;
    }

    .implant-img img {
        width: 80%;
    }

    .title-container {
        margin-top: 50px;
        width: 100%;
        text-align: center;
    }

    .title-container h1 {
        font-size: 12vw;
    }

    .title-container h2 {
        font-size: 2rem;
    }

    .title-container div {
        margin-top: 5px;
        justify-content: center;
    }

    .encart-blanc {
        display: none;
    }

    .encart-small {
        display: block;
        background-color: white;
        border-radius: 20px;
        opacity: 0;
        margin-top: -350px;
        width: 80%;
        padding: 40px;
        transition: transform 1s ease-out, opacity 0.7s ease-out;
    }
    .text-landing {
        font-size: 1.2rem;
    }

    .souris-container {
        width: 100%;
        display: flex;
        justify-content: left;
    }

    .souris-container img {
        width: 90%;
    }

    .copyright1 {
        font-size: 12px;
    }
}

@media (max-width: 767px) {
    .implant-container-small {
        width: 100%;
    }

    .encart-small {
        background-color: white;
        margin-top: -150px;
    }
}
</style>